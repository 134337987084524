/* Mixins */
/* Groups animations to only apply them
   When the element is in view.
   Also disables animations on devices featuing reduced motion.
 */
/* Maintains element aspect ratio 
*/
/* Orientation 
   - Target device orientation such as portrait or landscape
*/
/* Retina 
   - Target high resolution / retina devices.
   Useful for displaying high resolution graphics to devices that
   can support them. Retina will also target print as retina is designed
   not to change content but make it sharper.
*/
/* Reduced Motion 
   - Target devices where the user has set their preference for reduced motion.
   Animation mixins & functions already account for reduced motion and block animations.
*/
/* Print
   - Target print mode. Useful for hiding content that
   is not relevant on a printed page. Example a tags could be replaced with the actual urls.
*/
/* Standalone
   - Target standalone mode. Useful for changing content based on if a PWA is installed.
*/
/* Responsive Font based off breakpoints */
/* Image background */
#__next {
  transition: transform 0.5s ease; }

.modal-active #__next {
  opacity: 0.9; }

.modal-active .modal-background {
  background: rgba(0, 0, 0, 0.7); }

.fp-enabled .modal-active #__next {
  transform: scale(0.9); }

.fp-enabled .modal-active .modal-background {
  background: rgba(0, 0, 0, 0.7); }

#modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
  #modal-container .modal {
    transition: transform 0.5s ease;
    transform: scale(0.8) translateY(1000px); }
  #modal-container .modal-background {
    transition: opacity 0.5s ease; }
  #modal-container.active {
    animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
    #modal-container.active .modal {
      transform: scale(1) translateY(0px); }
  #modal-container .modal-background {
    display: table-cell;
    text-align: center;
    vertical-align: middle; }
    #modal-container .modal-background .modal {
      background: white;
      display: inline-block;
      border-radius: 3px;
      font-weight: 300;
      position: relative;
      overflow: hidden;
      padding: 10px; }
      #modal-container .modal-background .modal .content {
        background: #fff;
        border-radius: 3px; }
  #modal-container h2 {
    color: RGBA(18, 74, 133, 1);
    text-transform: uppercase;
    font-size: 20px;
    text-align: left;
    border-bottom: 1px solid RGBA(18, 74, 133, 0.4);
    padding: 30px;
    padding-left: 40px;
    background: #13548f;
    color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    @media only screen and (min-width: 54.85714em) {
      #modal-container h2 {
        font-size: 20px;
        line-height: 20px; } }
    @media only screen and (min-width: 70.85714em) {
      #modal-container h2 {
        font-size: 35px;
        line-height: 35px; } }
    @media only screen and (min-width: 85.71429em) {
      #modal-container h2 {
        font-size: 35px;
        line-height: 35px; } }
    @media only screen and (min-width: 114.28571em) {
      #modal-container h2 {
        font-size: 35px;
        line-height: 35px; } }
    @media only screen and (min-width: 128.57143em) {
      #modal-container h2 {
        font-size: 35px;
        line-height: 35px; } }
  #modal-container p {
    margin: 20px;
    max-width: 500px;
    text-align: left;
    line-height: 25px; }
  #modal-container .modal-actions {
    text-align: right;
    padding: 10px; }

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: visible; }
  99% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  99% {
    opacity: 0;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.fields {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px; }
  .fields .field {
    flex: 50%;
    padding: 10px;
    box-sizing: border-box;
    text-align: left; }
  .fields label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px; }
  .fields .error {
    float: right;
    font-weight: normal;
    color: #666; }
  .fields input,
  .fields textarea,
  .fields select {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
    padding-left: 20px;
    border-radius: 0px;
    outline: none;
    border: 1px solid #061828;
    font-size: 14px;
    transition: border-color 0.5s ease, box-shadow 0.5s ease; }
    .fields input::placeholder,
    .fields textarea::placeholder,
    .fields select::placeholder {
      color: #666;
      transition: color 0.5s ease; }
    .fields input:focus,
    .fields textarea:focus,
    .fields select:focus {
      border-color: #13548f;
      color: #13548f;
      -webkit-box-shadow: inset 0px 0px 0px 2px #13548f;
      -moz-box-shadow: inset 0px 0px 0px 2px #13548f;
      box-shadow: inset 0px 0px 0px 2px #13548f; }
      .fields input:focus::placeholder,
      .fields textarea:focus::placeholder,
      .fields select:focus::placeholder {
        color: #ccc; }
  .fields textarea {
    min-height: 113px;
    max-width: 100%;
    width: 100%; }
  .fields .actions {
    text-align: center; }

@media only screen and (min-width: 54.85714em) and (max-width: 54.85714em) {
  .fields {
    width: 100%; } }

@media only screen and (max-width: 54.85714em) {
  .fields {
    width: 100%; } }

@media only screen and (min-width: 70.85714em) and (max-width: 70.85714em) {
  .fields {
    flex-direction: column; }
  .modal-background {
    overflow: auto; } }

@media only screen and (max-width: 70.85714em) {
  .fields {
    flex-direction: column; }
  .modal-background {
    overflow: auto; } }
